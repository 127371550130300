define("iris/models/partner", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "iris/mixins/anonymize"], function (_exports, _model, _attr, _relationships, _emberCpValidations, _anonymize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      descriptionKey: 'description'
    }), (0, _emberCpValidations.validator)('length', {
      max: 254
    })],
    shortName: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 50
    })],
    code: [(0, _emberCpValidations.validator)('length', {
      max: 10
    }), (0, _emberCpValidations.validator)('presence', {
      dependentKeys: ['model.multiOwnerPartner'],
      disabled: Ember.computed(function () {
        return !this.get('model').get('multiOwnerPartner');
      }).volatile(),
      presence: true
    })],
    lotusCode: (0, _emberCpValidations.validator)('length', {
      max: 250
    }),
    tax: (0, _emberCpValidations.validator)('length', {
      max: 20
    }),
    value: (0, _emberCpValidations.validator)('length', {
      max: 20
    }),
    invoicePayment: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    currencyPayment: [(0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = _model.default.extend(Validations, _anonymize.default, {
    changeTracker: {
      trackHasMany: true,
      enableIsDirty: true,
      auto: true
    },
    modelName: 'Partner',
    sessionAccount: Ember.inject.service(),
    name: (0, _attr.default)('string', {
      required: true
    }),
    email: (0, _attr.default)('string', {
      required: true
    }),
    shortName: (0, _attr.default)('string', {
      required: true
    }),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true,
      required: true
    }),
    code: (0, _attr.default)('string', {
      required: true
    }),
    lotusCode: (0, _attr.default)('string'),
    logo: (0, _attr.default)('string'),
    tax: (0, _attr.default)('string', {
      required: true
    }),
    taxEu: (0, _attr.default)('string', {
      required: true
    }),
    taxGroup: (0, _attr.default)('string', {
      required: true
    }),
    paymentDays: (0, _attr.default)('number', {
      defaultValue: 15,
      required: true
    }),
    paymentDisciplinePositiveDays: (0, _attr.default)('number', {
      defaultValue: 15,
      required: true
    }),
    paymentDisciplineNegativeDays: (0, _attr.default)('number', {
      defaultValue: 15,
      required: true
    }),
    periodicalSettlement: (0, _attr.default)('boolean', {
      defaultValue: true,
      required: true
    }),
    financeContactEmail: (0, _attr.default)('string', {
      required: true
    }),
    multiOwnerPartner: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    approved: (0, _attr.default)('boolean'),
    domestic: (0, _attr.default)('boolean', {
      defaultValue: true,
      required: true
    }),
    invoicePayment: (0, _relationships.belongsTo)('paymentMethod'),
    currencyPayment: (0, _relationships.belongsTo)('currency'),
    partnerSector: (0, _relationships.belongsTo)('partnerSector'),
    parent: (0, _relationships.belongsTo)('partner', {
      inverse: 'children'
    }),
    kam: (0, _relationships.belongsTo)('account'),
    partnerBankcodes: (0, _relationships.hasMany)('partnerBankcode'),
    addresses: (0, _relationships.hasMany)('address'),
    partnerTypes: (0, _relationships.hasMany)('partnerType'),
    users: (0, _relationships.hasMany)('userPartnerAssoc'),
    invoices: (0, _relationships.hasMany)('invoice', {
      inverse: 'partner'
    }),
    departments: (0, _relationships.hasMany)('department'),
    contacts: (0, _relationships.hasMany)('contact', {
      inverse: 'partner'
    }),
    siteZones: (0, _relationships.hasMany)('siteZone'),
    files: (0, _relationships.hasMany)('project', {
      inverse: 'clientPartner'
    }),
    contracts: (0, _relationships.hasMany)('contract', {
      inverse: 'clientPartner'
    }),
    partnerActionCompetencyAssocs: (0, _relationships.hasMany)('partnerActionCompetencyAssoc'),
    children: (0, _relationships.hasMany)('partner', {
      inverse: 'parent'
    }),

    init() {
      this._super(...arguments);
    },

    initRelatedRecords() {
      let region = this.get('sessionAccount.region');

      if (region) {
        region.get('ownerPartner').then(ownerPartner => {
          if (ownerPartner) {
            ownerPartner.get('currencyPayment').then(currency => {
              this.set('currencyPayment', currency);
            });
          }
        });
      }

      this.store.find('paymentMethod', 'SYS_04').then(paymentMethod => {
        this.set('invoicePayment', paymentMethod);
      });
    },

    billingAddress: Ember.computed('addresses.[]', {
      get() {
        let value = null;

        let _this = this;

        this.get('addresses').then(addresses => {
          let billingAddress = addresses.find(address => {
            return address.get('isBillingAddress');
          });

          _this.set('billingAddress', billingAddress);
        });
        return value;
      },

      set(key, value) {
        return value;
      }

    }),
    domesticPartner: Ember.computed.bool('billingAddress.id', 'SYS_01'),
    // burnt-in
    finances: Ember.computed.filterBy('users', 'finance', 0),
    _financesUsers: Ember.computed.mapBy('finances', 'user'),
    financesUsers: Ember.computed.mapBy('_financesUsers', 'content'),
    _usersUsers: Ember.computed.mapBy('users', 'user'),
    userPartnerAssocs: Ember.computed.mapBy('_usersUsers', 'content'),
    sortBy: ['datePaymentDue'],
    sortedInvoices: Ember.computed.sort('invoices', 'sortBy'),
    nettos: Ember.computed.mapBy('invoices', 'netto'),
    volumen: Ember.computed.sum('nettos'),
    invoicesYear: Ember.computed.filterBy('invoices', 'currentYear'),
    yearlyNetto: Ember.computed.mapBy('invoicesYear', 'netto'),
    yearlyVolumen: Ember.computed.sum('yearlyNetto'),
    paidInvoices: Ember.computed.filterBy('invoicesYear', 'isPaid'),
    notPaidInvoices: Ember.computed.filterBy('invoicesYear', 'isPaid', false),
    notPaidInvoicesByNetto: Ember.computed.mapBy('notPaidInvoices', 'netto'),
    notPaidInvoicesTotal: Ember.computed.sum('notPaidInvoicesByNetto'),
    expiredInvoices: Ember.computed.filter('paidInvoices', invoice => {
      return invoice.get('defaultInPaying') > 0;
    }),
    avarageExpiration: Ember.computed('expiredInvoices', function () {
      let expiredInvoices = this.get('expiredInvoices');
      let defaultInPaying = expiredInvoices.mapBy('defaultInPaying');
      let sum = defaultInPaying.reduce((sum, defaultInPaying) => {
        return sum + defaultInPaying;
      }, 0);
      return sum / expiredInvoices.length;
    }),
    defaultInPaying: Ember.computed.mapBy('paidInvoices', 'defaultInPaying'),
    // ======================== Files ===========================
    opportunities: Ember.computed.filterBy('files', 'isOpportunity'),
    thisYearOpportunities: Ember.computed.filterBy('opportunities', 'fileYear'),
    yearlyWeightedIncomes: Ember.computed.mapBy('thisYearOpportunities', 'weightedIncome'),
    sumYearlyWeightedIncomes: Ember.computed.sum('yearlyWeightedIncomes'),
    weightedIncomes: Ember.computed.mapBy('opportunities', 'weightedIncome'),
    sumWeightedIncomes: Ember.computed.sum('weightedIncomes'),
    // ====================== Potentials ========================
    yearlyPotential: Ember.computed('sumYearlyWeightedIncomes', 'yearlyVolumen', function () {
      return this.get('sumYearlyWeightedIncomes') + this.get('yearlyVolumen');
    }),
    potential: Ember.computed('sumWeightedIncomes', 'volumen', function () {
      return this.get('sumWeightedIncomes') + this.get('volumen');
    }),
    supplier: (0, _attr.default)('number', {
      readOnly: true
    }),
    customer: (0, _attr.default)('number', {
      readOnly: true
    }),
    subcontractor: (0, _attr.default)('number', {
      readOnly: true
    }),
    expiredDays: (0, _attr.default)('number', {
      readOnly: true
    }),
    paymentDisciplinePositive: (0, _attr.default)('number', {
      readOnly: true
    }),
    paymentDisciplineNegative: (0, _attr.default)('number', {
      readOnly: true
    })
  });

  _exports.default = _default;
});